import {
  getNoScriptSnippet,
  getScriptSnippet,
  GTM_NO_SCRIPT_BASE,
  GTM_SCRIPT_BASE,
  GTM_SCRIPT_IDENTIFIER,
} from "@hooks/useGoogleTagManager/constants";

type GoogleTagManager = {
  initialize: () => void;
  removeTags: () => void;
};

const useGoogleTagManager = (gtmKey?: string, gtmIsActive?: boolean): GoogleTagManager => {
  const removeExistingTags = (): void => {
    const existingScripts = Array.from(document.getElementsByTagName("script"));
    existingScripts.forEach((script) => {
      if (
        script.src.includes(GTM_SCRIPT_BASE) ||
        script.innerHTML.includes(GTM_SCRIPT_IDENTIFIER)
      ) {
        script.remove();
      }
    });

    const existingNoScripts = Array.from(document.getElementsByTagName("noscript"));
    existingNoScripts.forEach((noscript) => {
      if (noscript.innerHTML.includes(GTM_NO_SCRIPT_BASE)) {
        noscript.remove();
      }
    });
  };

  const noScript = (): HTMLElement => {
    const noscript = document.createElement("noscript");
    noscript.innerHTML = getNoScriptSnippet(gtmKey);
    return noscript;
  };

  const script = (): HTMLElement => {
    const script = document.createElement("script");
    script.innerHTML = getScriptSnippet(gtmKey);
    return script;
  };

  const initialize = (): void => {
    if (!gtmKey || !gtmIsActive) {
      removeExistingTags();
      return;
    }
    removeExistingTags();
    document.head.insertBefore(script(), document.head.childNodes[0]);
    document.body.insertBefore(noScript(), document.body.childNodes[0]);
  };

  return { initialize, removeTags: removeExistingTags };
};

export default useGoogleTagManager;
